import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

import { Cookies } from "react-cookie"
// import { useRedditAuth, useIsLoggedIn } from "../utils/hooks"
import { useRedditClient, useIsLoggedIn } from "../utils/hooks"


import RedditClient from "../utils/RedditClient"

const LoginHandler = () => {
  // const REDIRECT_URI = "http://localhost:8000/"
  const REDIRECT_URI = `${process.env.REDIRECT_URI}`
  const CLIENT_ID = `${process.env.CLIENT_ID}`
  // const CLIENT_ID = "BnEVt0xoYdMkaw"

  // const client = new RedditClient(CLIENT_ID, "", REDIRECT_URI)
  const client = useRedditClient()

  const [pressed, setButtonPressed] = React.useState(false)
  // const [isloggedin, setIsloggedin] = Reacy.useState(false)
  const isloggedin = useIsLoggedIn()

  // const [code, setCode] = React.useState(null)
  // const [accessToken, setAccessToken] = React.useState(null)

  // const [cookies, setCookie] = useCookies(["access_token"])

  const authenticate = async () => {
    client.authenticate()
  }

  React.useEffect(() => {


    


    if (!isloggedin && pressed && client) {
      authenticate()
      setButtonPressed(false)
    }


    if (!isloggedin && window.location.href != REDIRECT_URI && client) {
      authenticate()
    }
    
  }, [client, pressed])

  return (
    <Button>
      <VpnKeyOutlinedIcon
        onClick={() => setButtonPressed(true)}
        style={{ color: "white" }}
      />
    </Button>
  )
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <Grid container spacing={4} style={{ padding: "0 10vw" }}>
      <Grid item md={6}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
          }}
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
      </Grid>

      <Grid item md={6}>
        <Grid container justify="flex-end" alignContent="center">
          <LoginHandler />
        </Grid>
      </Grid>
    </Grid>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
